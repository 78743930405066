import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/empresa', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros"}},
      { path: '/preguntas-frecuentes/:table?', component: require(page+'faqs/index.vue').default, meta:{title:"Preguntas frecuentes"}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, name: 'contacto', meta:{title:"Contacto"}},
      { path: '/bolsa-de-trabajo', component: require(page+'job-board/index.vue').default, name: 'contacto', meta:{title:"Bolsa de trabajo"}},
      { path: '/distribuidor', component: require(page+'dealers/index.vue').default, name: 'distribuidorPage', meta:{title:"Distribuidor"}},
      { path: '/solicitud-de-credito', component: require(page+'credit/index.vue').default, name: 'contacto', meta:{title:"Solicitud de crédito"}},

      { path: '/aviso-de-privacidad', component: require(page+'text-page/aviso.vue').default, meta:{title:"Aviso de Privacidad"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-page/terminos.vue').default, meta:{title:"Términos y condiciones"}},
      // { path: '/politicas-de-uso', component: require(page+'text-page/politicas.vue').default, meta:{title:"Políticas de uso"}},
      // { path: '/trabaja-en-equipo', component: require(page+'text-page/trabaja-en-equipo.vue').default, meta:{title:"Trabaja en equipo y únete a PCH"}},
      // { path: '/garantias-devoluciones-y-cancelaciones', component: require(page+'text-page/garantias-devoluciones-y-cancelaciones.vue').default, meta:{title:"Garantías, Devoluciones y Cancelaciones"}},
      // { path: '/facturacion', component: require(page+'text-page/facturacion.vue').default, meta:{title:"Facturación"}},
      // { path: '/quiero-ser-proveedor', component: require(page+'text-page/quiero-ser-proveedor.vue').default, meta:{title:"Quiero ser proveedor"}},
      // { path: '/formas-de-pago', component: require(page+'text-page/formas-de-pago.vue').default, meta:{title:"Formas de pago"}},

      { path: '/productos', component: require(page+'products/results.vue').default, meta:{title:"Productos"}},
      { path: '/categoria/:id?', component: require(page+'products/category.vue').default, meta:{title:"Categoria"}},
      { path: '/subcategoria/:id?', component: require(page+'products/subcategory.vue').default, meta:{title:"Categoria"}},
      { path: '/brand/:id?', component: require(page+'products/brand.vue').default, meta:{title:"Marca"}},

      { path: '/producto/:id?', component: require(page+'products/description.vue').default, meta:{title:"Producto"}},
      { path: '/busqueda', component: require(page+'products/results.vue').default, meta:{title:"Busqueda"}},

      { path: '/blog', name: "blogPage", component: require(page+'blog/index.vue').default, meta:{title:"Blog"}},
      { path: '/blog/:id?', component: require(page+'blog/detail.vue').default, meta:{title:"Artículo de blog"}},

      { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},
      { path: '/historial-de-compras', component: require(page+'carrito/historial.vue').default, meta:{title:"Mi historial de compras"}},
      { path: '/mis-favoritos', component: require(page+'carrito/mis-favoritos.vue').default, meta:{title:"Mis favoritos"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},

            {
                path: 'historial-compras',
                component: require(page+'usuario-cuenta/historial-compras.vue').default,
                meta:{ title: 'Mis pedidos' }
            },
            {
                path: 'pedido/:id?',
                component: require(page+'usuario-cuenta/orderdetails.vue').default,
                meta:{ title: 'Detalle del pedido' }
            },

  			// {
  			//   path: 'nueva-cotizacion',
  			//   component: require(page+'usuario-cuenta/nueva-cotizacion.vue'),
  			//   meta:{ title: 'Mis cotizaciones' }
  			// },
  			// {
  			//   path: 'mis-cotizaciones',
  			//   component: require(page+'usuario-cuenta/mis-cotizaciones.vue'),
  			//   meta:{ title: 'Mis facturas' }
  			// }
  	  	]
  		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},50);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Bonampak", filter: (title)=>{ return title+" - Bonampak"; } }
);

// export {routes};
export default MyRouter;
