<template lang="html">
  <div id="about-us-page-v2">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-6 col-text">
            <h1 class="mb-2 title-s1 c-primary">Nosotros</h1>

            <!-- <h5 class="subtitle"></h5> -->
            <p>
              En Refacciones Bonampak, nos especializamos en proveer una amplia gama de productos refaccionarios de alta calidad para camiones, coches y camionetas. Con sucursales estratégicamente ubicadas en Ocosingo y Palenque, Chiapas, estamos dedicados a servir las necesidades de nuestros clientes en toda la región.
            </p>
            <p class="mt-3">
              Fundada con el compromiso de liderar en la industria automotriz, Refacciones Bonampak se distingue por su enfoque innovador y su excelencia en el servicio al cliente. Nos enorgullece ofrecer soluciones eficientes y accesibles a nuestros clientes y socios comerciales, asegurando siempre productos que cumplen con los más altos estándares de calidad.
            </p>
            <p class="mt-3">
              Creemos en establecer relaciones duraderas con nuestros clientes, basadas en la confianza y el respeto mutuo. A medida que seguimos creciendo y expandiendo nuestras operaciones, nos mantenemos fieles a nuestra visión de transformar el sector de distribución y uso de refacciones automotrices, contribuyendo a un futuro más sostenible para todos.
            </p>
            <!-- <p class="f-w-600">
              Renault, Mercedes – Benz, Volvo, Volkswagen
            </p> -->

            <!-- <div class="b-logos">
              <img src="public/images/pages/about-us/logos.png">
            </div> -->

            <!-- <h6 class="mt-4 subtitle">¡CON PRESENCIA EN 13 PAÍSES DE LA UNION EUROPEA EN CENTRO SUDAMÉRICA!</h6> -->
          </div>
        </div>
      </div>
    </section>

    <MissionComponent></MissionComponent>
  </div>
</template>

<script>
import MissionComponent from './mission-component.vue';
export default {
  components: {
    MissionComponent
  },

  data(){
    return{
      content:null,
      image: null,
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/about')).then((response)=>{
        this.content = response.data.body;
        this.image = response.data.image;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
