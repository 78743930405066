<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <!-- <div class="col-lg-6 col-image">
        </div> -->

        <div class="col-lg-6 col-info" id="faqs-box">
          <h1 class="mb-4 page-title" style="text-trasform: inherit;">Preguntas frecuentes</h1>
          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Dónde se encuentran?</h5>

              <div class="a">
                <p>
                  Estamos ubicados en Prv. Río Bósforo 25922 Fracc. Riberas de Sacramento. Chihuahua, Chihuahua, México.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Qué horario de atención manejan?</h5>

              <div class="a">
                <p>
                  Lunes a viernes de 8 a.m. a 7 p.m. (Horario Corrido)<br />
                  Sábado 9:00 a.m. a 2:00 p.m.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuál es el costo de reparar un equipo Windows?</h5>

              <div class="a">
                <p>
                  Esta dentro de un rango aproximado $900 a $1200 en función del servicio que requiera.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuál es el costo de reparar un equipo Apple?.</h5>

              <div class="a">
                <p>
                  Rango aproximado de $1,300 a $2,000 en función del servicio que requiera.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuál es el costo por formatear un equipo?</h5>

              <div class="a">
                <p>
                  Windows $850 más IVA
                  <br />
                  Mac $1,300 más IVA
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Dan descuentos por volumen de compra?</h5>

              <div class="a">
                <p>
                  Sí
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuentan con facilidades de pago?</h5>

              <div class="a">
                <p>
                  Sí , también aceptamos todas las tarjetas de crédito a meses sin intereses excepto AMEX, pagos con tarjeta de débito, transferencias eletrónicas para el pago de todas tus compras.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuál es el procedimiento de compra?</h5>

              <div class="a">
                <p>
                  Definir producto o servicio a adquirir, realizar pago y entrega del producto o servicio.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">Necesito un equipo, pero desconozco de datos técnicos. ¿Ofrecen asesoría?</h5>

              <div class="a">
                <p>
                  Si, le sugerimos contactar a uno de nuestros asesores.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuánto cobran por quitar virus de una computadora?</h5>

              <div class="a">
                <p>
                  Rango aproximado de $450 a $650 en función del servicio que requiera.
                </p>
              </div>
            </div>
          </div>

          <div class="box-qa">
            <div class="qa">
              <h5 class="q">¿Cuentan con envíos?</h5>

              <div class="a">
                <p>
                  Si, dentro del estado de Chihuahua y a los estados de la república.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/faq')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
