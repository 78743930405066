<template lang="html">
  <footer id="footer">
    <div class="container oversized-container">

      <div class="row align-items-center row-top">
        <div class="col-lg-5 col-left col-newsletter">
          <b-form @submit="onSubmit">
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder="Escribe tu correo..."
              required
            ></b-form-input>

            <b-button type="submit" class="btn-submit">Inscribirse</b-button>
          </b-form>
        </div>

        <div class="col-lg col-middle">
          <h6>Sé el primero en enterarte</h6>
          <h4>Regístrate a nuestro newsletter</h4>
        </div>

        <div class="col-lg row mx-0 col-right">
          <router-link class="logo" to="/"><img src="public/images/logo-white.svg"></router-link>
        </div>
      </div>

      <div class="row row-bottom">
        <!-- <div class="col-lg-5 col-menus">
          <div class="row">
            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Información</h6>

              <p class="my-1">
                <router-link to="/empresa">Empresa</router-link>
              </p>
              <p class="my-1">
                <router-link to="">Bolsa de trabajo</router-link>
              </p>
              <p class="my-1">
                <router-link to="/contacto">Contáctanos</router-link>
              </p>
            </div>

            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Mapa del sitio</h6>

              <p class="my-1">
                <router-link to="/">Inicio</router-link>
              </p>
              <p class="my-1">
                <router-link to="/solicitud-de-credito">Solicitud de crédito</router-link>
              </p>
              <p class="my-1">
                <router-link to="">Productos</router-link>
              </p>
            </div>

            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Links de acceso</h6>

              <p class="my-1">
                <router-link to="/registrarse">Registrate</router-link>
              </p>
              <p class="my-1">
                <router-link to="/login">Inicia sesión</router-link>
              </p>
              <p class="my-1">
                <router-link to="/recuperar-contrasena">Recuperar contraseña</router-link>
              </p>
            </div>
          </div>
        </div> -->

        <div class="col-sm-6 col-lg-4 col-middle align-self-center">
          <div class="box-networks">
            <a class="t-150 btn-network fb" target="_blank" href="https://www.facebook.com/RefaccionariaBonampak"><i class="fab fa-facebook-f"></i></a>
            <a class="t-150 btn-network ig" target="_blank" href="https://www.instagram.com/refaccionesbonampak?fbclid=IwY2xjawEp21tleHRuA2FlbQIxMAABHausWw_fcqFK1zNdrvJs6Q4AO19tby_6_cipZ02LgAYc_9Ls2jNPiW2h2w_aem_ra0uIqZ4sTBk8kAcUlFZSA"><i class="fab fa-instagram"></i></a>
            <!-- <a class="t-150 btn-network tw" href="#"><i class="fab fa-twitter"></i></a> -->
            <!-- <a class="t-150 btn-network lk" href="#"><i class="fab fa-linkedin-in"></i></a> -->
          </div>

          <p class="my-2">
            <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
          </p>
          <p class="my-2">
            <router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
          </p>
          <p class="my-2">
            <span>Derechos Reservados Bonampak Refacciones {{ currentYear }}</span>
          </p>
          <p class="my-2">
            <span><i class="icon"><i class="far fa-envelope"></i></i> contacto@bonampak.online</span>
          </p>
        </div>

        <div class="col-sm-6 col-lg-4 col-info">
          <h6 class="c-title">Sucursal Ocusingo</h6>
          <p>
            <span class="p-icon icon-map">Internacional km 82-Altos, Guadalupe, 29950 Ocosingo, Chiapas.</span>
          </p>
          <p class="mt-3">
            <span class="p-icon icon-phone">Tel. 919 113 7474</span>
          </p>
          <p class="mt-3">
            <a class="p-icon icon-location" target="_blank" href="https://www.google.com/maps/place/Refacciones+Bonampak/@16.8964447,-92.099118,15z/data=!4m2!3m1!1s0x0:0x2d3b4687f17520fd?sa=X&ved=1t:2428&ictx=111">Ver en Google Maps</a>
          </p>
        </div>

        <div class="col-sm-6 col-lg-4 col-info">
          <h6 class="c-title">Sucursal Palenque</h6>
          <p>
            <span class="p-icon icon-map">Tulipanes 6, Guayacan la Lomita, 29960 Palenque, Chiapas.</span>
          </p>
          <p class="mt-3">
            <span class="p-icon icon-phone">Tel. 916 283 0638</span>
          </p>
          <p class="mt-3">
            <a class="p-icon icon-location" target="_blank" href="https://www.google.com/maps/place/Refaccionaria+bonampak/@17.5196334,-91.9939069,15z/data=!4m2!3m1!1s0x0:0x69e3592505a0b3f4?sa=X&ved=1t:2428&ictx=111">Ver en Google Maps</a>
          </p>

          <!-- <div class="box-cert mt-3">
            <span>Certificado de pagos</span><br />
            <span class="safe">Compras seguras</span>
          </div> -->
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
      info: {
        email:null,
        contact:null,
        address:null,
      },
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
    this.getInfo();
  }
}
</script>
