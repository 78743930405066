<template lang="html">
  <section class="mission-vision-section-comp">
    <div class="container">
      <div class="row row-content">
        <!-- <div class="col-12 col-title">
          <h2>Nuestros servicios</h2>
        </div> -->

        <div class="col-md-6 col-lg-6 col-service">
          <h5>MISIÓN</h5>
          <hr />
          <p>
            Nos dedicamos a ofrecer a nuestros clientes y socios comerciales una amplia selección de productos refaccionarios para camiones, coches y camionetas, buscando su reconocimiento por la calidad superior y precios competitivos. Aspiramos a superar las expectativas del consumidor final mediante estrategias de distribución efectivas y un compromiso constante con la innovación y la excelencia.
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-image">
          <img src="public/images/pages/about-us/mission.svg">
        </div>
      </div>

      <div class="row row-content">
        <div class="col-md-6 col-lg-6 col-service">
          <h5>VISIÓN</h5>
          <hr />
          <p>
            Ser el líder indiscutible en la industria de refacciones automotrices para camiones, coches y camionetas, reconocido por nuestra innovación, calidad y servicio excepcional. Nos comprometemos a expandir nuestra presencia global, estableciendo estándares de excelencia que anticipen las necesidades del mercado y promuevan relaciones duraderas con nuestros clientes y socios. Aspiramos a transformar la manera en que se distribuyen y utilizan las refacciones, contribuyendo a un futuro más eficiente y sostenible en el sector automotriz.
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-image">
          <img src="public/images/pages/about-us/vision.svg">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
