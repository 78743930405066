<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Productos</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-lg-4 col-s-title">
          <h2 class="text-center text-lg-left title">Busqueda: <small>{{ $route.query.keywords }}</small></h2>
        </div>

        <div class="col-12 col-lg-8 col-order">
          <div class="txt">
            Ordenar por:
          </div>

          <b-form-select v-model="filtersForm.order" class="mb-3">
            <b-form-select-option value="1">Precio Menor a mayor</b-form-select-option>
            <b-form-select-option value="2">Precio Mayor a menor</b-form-select-option>
            <b-form-select-option value="3">Alfabeto</b-form-select-option>
          </b-form-select>
        </div>
      </div>

      <div class="row align-items-start">

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
            <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+ $root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="note" v-if="product.isNew">Nuevo</div>
                  <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ product.name }}</h6>
                  <h6 class="brand">{{ product.brand }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!products.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      filtersForm: {
        precio: null,
        order: 0
      },
      filters:{
        keywords:null,
        categories_id:null,
        brands_id:null,
      },
      products: [
        {
          "id": 5,
          "sku": "LU23535017",
          "name": "LU23535017 BOMBA DE AGUA SERIE 60 DD5 DD6 ENGRANE GDE MODERNA",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
        {
          "id": 4,
          "sku": "LUCH101",
          "name": "LUCH101 CABEZA WABCO 906 CARGA",
          "price": "850.00",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-4.png"
        },
        {
          "id": 2,
          "sku": "LUCC114",
          "name": "LUCC103 COMPRESOR CUMMINS ISB",
          "price": "450.00",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-2.jpg"
        },
        {
          "id": 3,
          "sku": "LUCG100",
          "name": "LUCG100 ENGRANE COMP. WABCO",
          "price": "289.00",
          "stock": 5,
          "imageUrl": "public/images/pages/products/product-3.png"
        },
        {
          "id": 1,
          "sku": "LUCC111",
          "name": "LUCC100 COMPRESOR WABCO 904/906 URBANO VAD1100040001 YUM01.04.030",
          "price": "3050.00",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-1.jpeg"
        },
        {
          "id": 5,
          "sku": "LU23535017",
          "name": "LU23535017 BOMBA DE AGUA SERIE 60 DD5 DD6 ENGRANE GDE MODERNA",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
        {
          "id": 4,
          "sku": "LUCH101",
          "name": "LUCH101 CABEZA WABCO 906 CARGA",
          "price": "850.00",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-4.png"
        },
        {
          "id": 2,
          "sku": "LUCC114",
          "name": "LUCC103 COMPRESOR CUMMINS ISB",
          "price": "450.00",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-2.jpg"
        },
        {
          "id": 3,
          "sku": "LUCG100",
          "name": "LUCG100 ENGRANE COMP. WABCO",
          "price": "289.00",
          "stock": 5,
          "imageUrl": "public/images/pages/products/product-3.png"
        },
        {
          "id": 1,
          "sku": "LUCC111",
          "name": "LUCC100 COMPRESOR WABCO 904/906 URBANO VAD1100040001 YUM01.04.030",
          "price": "3050.00",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-1.jpeg"
        },
        {
          "id": 1,
          "sku": "LUCC111",
          "name": "LUCC100 COMPRESOR WABCO 904/906 URBANO VAD1100040001 YUM01.04.030",
          "price": "3050.00",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-1.jpeg"
        },
        {
          "id": 5,
          "sku": "LU23535017",
          "name": "LU23535017 BOMBA DE AGUA SERIE 60 DD5 DD6 ENGRANE GDE MODERNA",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
      ],

    }
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },
    getSearch(){
      this.isLoading = true;
      axios.post(tools.url('/api/search'),this.filters).then((response)=>{
        this.products = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },
  watch:{
    '$route.query':function(){
      this.filters.keywords = this.$route.query.keywords;
      this.filters.categories_id = this.$route.query.category;
      this.filters.brands_id = this.$route.query.brands_id;
      this.isLoading = true;
      this.getSearch();
    },

  },

  mounted(){
    setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

    if(this.$route.query.keywords || this.$route.query.category || this.$route.query.brands_id){
       this.filters.keywords = this.$route.query.keywords;
       this.filters.categories_id = this.$route.query.category;
       this.filters.brands_id = this.$route.query.brands_id;
       this.getSearch();
    }
  },

  components: {
    LoadingComponent
  },
}
</script>
