<template lang="html">
    <div id="results-page">
  
      <section class="breadcrumb-section">
        <div class="container oversized-container">
          <span>Marcas</span>
          
         
        </div>
      </section>
  
      <section class="container oversized-container products-section">
        
  
        <div class="row align-items-start">
  

          <!-- Resultados en columnas -->
          <div class="col-lg">
            <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
              <div class="col-sm-12 col-md-12 col-xl-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
                <router-link class="outlined-box" :to="'/producto/'+ $root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="note" v-if="product.isNew">Nuevo</div>
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>
  
                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>
  
                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 text-center" v-if="!products.length && !isLoading">
                <h5>No se encontraron resultados</h5>
              </div>
  
              <div class="col-12" v-if="isLoading">
                <loading-component></loading-component>
              </div>
            </div>
          </div>
          <!--  -->
  
        </div>
  
      </section>
  
    </div>
  </template>
  
  <script>
  import LoadingComponent from '../shared/loading-component.vue';
  export default {
    components: {
      LoadingComponent
    },
  
    data(){
      return{
        isLoading: true,
        showFilters: false,
  
        filtersForm: {
          brands:[],
          categories:[],
          price:null,
        },
  
        products: [],

      }
    },
  
    watch: {
      "$route.params.id" : function(v){
            this.brands_id = this.$root._getURLID(this.$route.params.id);
            this.getProducts();
      },
      
    },
  
    methods: {
      onSubmitFilters(evt) {
          evt.preventDefault();
  
      },
  
      getProducts: function(category_id){
          this.isLoading = true;
          this.filtersForm.brands_id = this.brands_id;
          axios.post(tools.url('/api/products'),this.filtersForm).then((response) =>{
              this.products = response.data;
       
              this.isLoading = false;
          }).catch((error)=>{
            console.log(error);
          });
      },
      
  
    },
  
    mounted(){
  
      setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar
  
      if(this.$route.params.id){
         this.brands_id = this.$root._getURLID(this.$route.params.id);
         this.getProducts();

      }
    },
  }
  </script>
  