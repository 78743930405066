<template lang="html">
  <div id="job-board-page">
    <!-- <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div> -->

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 text-center col-info">
          <h1 class="mb-2 page-title">¿Quieres unirte a nuestro equipo?</h1>

          <b-form @submit="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Nombre"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Correo electrónico"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="Teléfono"
                minlength="10"
                maxlength="10"
              ></b-form-input>
            </b-form-group>

            <h5 class="mb-2 f-w-600">Adjunta tu CV</h5>

            <b-form-group>
              <b-form-file
              name="cv"
                required
                v-model="form.cvFile"
                :state="Boolean(form.cvFile)"
                placeholder="Selecciona un archivo..."
                drop-placeholder="Suelta el archivo aquí..."
              ></b-form-file>
            </b-form-group>

            <p class="pt-2 text-center">
              <b-button type="submit" class="btn-send">Enviar</b-button>
            </p>
          </b-form>
        </div>
      </div>
    </section>

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119452.69716917786!2d-103.40545358012501!3d20.67377770932267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sca!4v1627506372341!5m2!1ses!2sca" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        cvFile: null
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('phone', this.form.phone);
        formData.append('cv', this.form.cvFile);

        axios.post(tools.url("/api/jobs"), formData).then((response)=>{
          this.makeToast('success', 'Informacion enviada correctamente', 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            phone: null
          };
          alert('Informacion enviada correctamente');
        }).catch( (error)=> {
            // console.log(error.response);
            let errors = error.response.data;
            // console.log(errors);
            this.makeToast('danger', 'Hubo un error al mandar la informacion', 'Error');
           
            alert('Hubo un error al mandar la informacion');
      });

     
    }
  },
}
</script>
