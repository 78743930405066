<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <!-- <div class="bg-image"></div> -->
    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <div class="col-lg-10 col-info" id="faqs-box">
          <h1 class="mb-2 page-title">Términos y Condiciones de Comercio Electrónico de Refacciones Bonampak</h1>

          <div class="text-justify">
            <p class="mb-2">
              Este contrato describe los términos y condiciones generales (en adelante únicamente "TÉRMINOS Y CONDICIONES") aplicables al uso de los servicios y funciones a través de la plataforma de Bonampak Refacciones.
            </p>
            <p>
              Cualquier persona que desee acceder o hacer uso de la plataforma, y/o los servicios que en ella se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a políticas y principios incorporados al presente documento. En todo caso, cualquier persona que no acepte los presentes términos y condiciones, deberá abstenerse de utilizar la plataforma, y a adquirir los productos y servicios que en su caso sean ofrecidos.
            </p>

            <br />
            <h5>1. DEL OBJETO.</h5>
            <p>
              El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización de la plataforma y del  sitio web entendiendo por este cualquier tipo de contenido, producto o servicio que se encuentre a disposición del público en general dentro del dominio: https://bonampak.online/<br />
              <br />
              Bonampak reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la configuración que pudiera estar contenida en la plataforma, en este sentido, el USUARIO reconoce y acepta que Bonampak en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman la plataforma o el acceso a los mismos.<br />
              <br />
              El acceso a la plataforma por parte del USUARIO tiene carácter libre y, por regla general es gratuito sin que el USUARIO tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada por el proveedor de este tipo de servicios que hubiere contratado el mismo USUARIO.<br />
              <br />
              La plataforma está dirigido principalmente a USUARIOS residentes en la República Mexicana, por lo cual, Bonampak no asegura que la plataforma cumpla total o parcialmente con la legislación de otros países, de forma que, si el USUARIO reside o tiene su domicilio establecido en otro país y decide acceder o utilizar la plataforma lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo a Bonampak ninguna responsabilidad que se pueda derivar de dicho acto.
            </p>

            <br />
            <h5>2. DEL USUARIO.</h5>
            <p>
              Es responsabilidad del USUARIO utilizar la plataforma de acuerdo a la forma en la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través la plataforma. Además, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos a través del SITIO WEB de manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del SITIO WEB.<br />
              <br />
              Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en la plataforma, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por la plataforma. En todo caso, el USUARIO notificará de forma inmediata al TITULAR acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.<br />
              <br />
              El sólo acceso la plataforma no supone el establecimiento de ningún tipo de relación entre Bonampak y el USUARIO.<br />
              <br />
              Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría de edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes TÉRMINOS Y CONDICIONES.
            </p>

            <br />
            <h5>3. FUNCIONAMIENTO.</h5>
            <p>
              Bonampak es una plataforma compuesta por un sitio web y sistemas en la nube que permte a los 2 tipos de usuarios siguientes realizar las funciones siguientes:<br />
              <br />
              USUARIO AFILIADO
            </p>
            <ul>
              <li>Gestión de su información de perfil.</li>
              <li>Gestión de categorías de productos a la venta.</li>
              <li>Gestión de cupones de descuento.</li>
              <li>Gestión de productos a la venta; platillos o productos.</li>
              <li>Consulta de reservaciones.</li>
              <li>Generación de códigos QR para publicación en sus mesas.</li>
              <li>Consulta de pedidos.</li>
            </ul>
            <p>
              USUARIO CLIENTE
            </p>
            <ul>
              <li>Lectura de código QR y acceso a la micro pagina auto generada del comercio afilado.</li>
              <li>Consulta de productos a la venta por categoría o búsqueda.</li>
              <li>Pedidos de productos a la venta.</li>
              <li>
                Selección de forma de entrega:
                <ul>
                  <li>Domicilio.</li>
                  <li>Recolectar.</li>
                  <li>Consumir ahí.</li>
                </ul>
              </li>
              <li>
                Selección de forma de pago:
                <ul>
                  <li>Débito o crédito.</li>
                  <li>En establecimiento.</li>
                </ul>
              </li>
              <li>Realización de pedido.</li>
              <li>Gestión de reservaciones.</li>
            </ul>

            <br />
            <h5>4. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</h5>
            <p>
              Bonampak no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, ofrecidos a través de la plataforma, no obstante, Bonampak llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen funcionamiento de la plataforma.<br />
              <br />
              De igual forma Bonampak no será responsable ni garantiza que el contenido o software al que pueda accederse a través del SITIO WEB, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el USUARIO accede.<br />
              <br />
              Bonampak tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del SITIO WEB. En ningún caso Bonampak será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del SITIO WEB.
            </p>

            <br />
            <h5>5. RESPONSABILIDAD.</h5>
            <ul>
              <li>Bonampak no es responsable de la calidad de los productos – platillos ofertados por sus afiliados.</li>
              <li>Bonampak no es responsable del servicio a domicilio realizado por sus afiliados o por terceros.</li>
              <li>Bonampak no es responsable por fallas o defectos de los productos – platillos ofertados por sus afiliados.</li>
              <li>Bonampak no es responsable de la autorización de pagos y cargos de las tarjetas de crédito / débito del usuario.</li>
              <li>Bonampak no es responsable de la seguridad del usuario al ingresar la información e su tarjeta de crédito y/o débito.</li>
            </ul>
            <p>
              Bonampak funge unicamente como intermediario entre el usuario y el afilado.
            </p>

            <br />
            <h5>6. DEVOLUCIONES.</h5>
            <p>
              Bonampak procederá a una devolución siempre y cuando el usuario comprador reporta el requerimiento de devolución con un limite de tiempo de hasta 3 días naturales después de la compra del mismo. La devolución sera valida unicamente cuando el producto recibido cuente con alguna de las condiciones siguientes:
            </p>
            <ul>
              <li>El producto - platillo no haya sido entregado.</li>
            </ul>
            <p>
              Cuando se proceda a una devolución sera descontado un 10% del total del costo del producto para gastos operativos de Bonampak por el proceso.
            </p>

            <br />
            <h5>7. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</h5>
            <p>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, Bonampak se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.<br />
              <br />
              Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través de la Plataforma será tratado de conformidad con los principios de licitud, calidad, finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no obstante, esta podrá darse a través de la propia utilizando los mecanismos habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el caso de datos personales sensibles, considerando por estos aquellos que debido a una utilización indebida puedan dar origen a discriminación o su divulgación conlleve un riesgo para el titular.<br />
              <br />
              En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.<br />
              <br />
              El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el Aviso de Privacidad el cual se encontrará disponible en la siguiente dirección electrónica:<br />
              https://bonampak.online/privacidad<br />
              <br />
              Bonampak se reserva el derecho a modificar su Política de Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización del Plataforma después de dichos cambios, implicará la aceptación de estos cambios.<br />
              <br />
              Por otra parte, el acceso a la Plataforma puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar a el SITIO WEB, almacenar las preferencias del USUARIO, así como la interacción que este tenga con el SITIO WEB, como por ejemplo: la fecha y hora en la que se accede a el SITIO WEB, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etc.<br />
              <br />
              Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores, y posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.<br />
              <br />
              En caso de que el USUARIO no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el USUARIO podrá continuar haciendo uso de el SITIO WEB, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.<br />
              <br />
              Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES, con objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del SITIO WEB.
            </p>

            <br />
            <h5>8. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h5>
            <p>
              Bonampak por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de la APP IOS, APP ANDROID y el SITIO WEB, entendiendo por este el código fuente que hace posible su funcionamiento así como las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del SITIO WEB, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización de Bonampak. <br />
              <br />
              El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del TITULAR. No obstante, además de poder visualizar los elementos del SITIO WEB podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.<br />
              <br />
              Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido, de la APP IOS, APP ANDROID y el SITIO WEB, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.<br />
              <br />
              En caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos de la Plataforma suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a Bonampak a través de los datos de contacto disponibles en el propio SITIO WEB y/o a través de los siguientes medios:<br />
              <br />
              Correo electrónico: legal@bonampak.online
            </p>

            <br />
            <h5>9. LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</h5>
            <p>
              Bonampak se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del SITIO WEB, sus contenidos, productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y CONDICIONES.<br />
              <br />
              La relación entre el USUARIO y Bonampak se regirá por la legislación vigente en México, específicamente en el Estado de Jalisco. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes TÉRMINOS Y CONDICIONES, las partes se someterán a la jurisdicción ordinaria de los tribunales que correspondan conforme a derecho en el estado al que se hace referencia.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/terminos')).then((response)=>{
        this.content = response.data.body;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
