<template lang="html">
  <div id="credit-page">
    <!-- <div class="bg-image"></div> -->
    <section class="container oversized-container content-section">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-info" id="faqs-box">
          <h1 class="mb-2 page-title">Solicitud de Crédito</h1>

          <div class="text-justify" v-html="content"></div>
        </div>
      </div>
      <sweet-modal ref="modalForm">
          <b-form @submit="onSubmit">

              <b-form-group>
                <b-form-input
                  v-model="form.company"
                  type="text"
                  required
                  placeholder="Nombre de la empresa"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.email"
                  type="text"
                  required
                  placeholder="Correo electronico"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.bussines_name"
                  type="text"
                  required
                  placeholder="Razon social"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.state"
                  type="text"
                  required
                  placeholder="Estado"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.town"
                  type="text"
                  required
                  placeholder="Ciudad"
                ></b-form-input>
              </b-form-group>

              <h5 class="mb-2 f-w-600">Acta constittiva</h5>
              <b-form-group>  
                <b-form-file
                  name="cv"
                    required
                    v-model="form.articles_incorporation"
                    :state="Boolean(form.articles_incorporation)"
                    placeholder="Selecciona un archivo..."
                    drop-placeholder="Suelta el archivo aquí..."
                  ></b-form-file>
              </b-form-group>

              <h5 class="mb-2 f-w-600">Cedula fiscal</h5>
              <b-form-group>
                <b-form-file
                  name="cv"
                    required
                    v-model="form.tax_identification"
                    :state="Boolean(form.tax_identification)"
                    placeholder="Selecciona un archivo..."
                    drop-placeholder="Suelta el archivo aquí..."
                  ></b-form-file>
              </b-form-group>

              <p class="text-center">
                <b-button type="submit" class="btn-send">Enviar</b-button>
              </p>

          </b-form>
      </sweet-modal>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
      form:{
          company:null,
          email:null,
          bussines_name:null,
          state:null,
          town:null,
          articles_incorporation:null,
          tax_identification:null,
      }
    }
  },

  methods: {
      getContent: function(){
        axios.get(tools.url('/api/credito')).then((response)=>{
              this.content = response.data.body;
        }).catch((error)=>{
              console.log(error);
        })
      },
      handleClick(){
            this.$refs.modalForm.open();
      },
      onSubmit(evt) {
      evt.preventDefault();

        const formData = new FormData();
        formData.append('company', this.form.company);
        formData.append('email', this.form.email);
        formData.append('bussines_name', this.form.bussines_name);
        formData.append('state', this.form.state);
        formData.append('town', this.form.town);
        formData.append('articles_incorporation', this.form.articles_incorporation);
        formData.append('tax_identification', this.form.tax_identification);

        axios.post(tools.url("/api/credit_requests"), formData).then((response)=>{
          this.$refs.modalForm.close();

          this.makeToast('success', 'Informacion enviada correctamente', 'Mensaje enviado');
          this.form = {
            company: null,
            email: null,
            bussines_name: null,
            state: null,
            town: null,
            articles_incorporation: null,
            statax_identificationte: null,
          };
          alert('Informacion enviada correctamente');
        }).catch( (error)=> {
            // console.log(error.response);
            let errors = error.response.data;
            // console.log(errors);
            this.makeToast('danger', 'Hubo un error al mandar la informacion', 'Error');
           
            alert('Hubo un error al mandar la informacion');
      });

     
    },
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },
  },
  mounted() {
      setTimeout(() => {
        // Selecciona el span por su ID
        const spanElement = document.getElementById('spanone');
        const spanElementTwo = document.getElementById('spantwo');
        if (spanElement) {
          // Adjunta el evento click al span
          spanElement.addEventListener('click', this.handleClick);
          spanElementTwo.addEventListener('click', this.handleClick);
        }
      }, 1500);
      
  },
  beforeMount(){
    this.getContent();
  }
}
</script>
